<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Solicitudes de empleados" :exportInfo="exportItems" cardTextFooter>
    <template v-slot:header-buttons>
      <v-btn @click="$router.push('/nueva-solicitud-empleados')" small text>
        Nueva solicitud
        <v-icon right>mdi-file-document-plus-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:buttons>
      <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
    </template>
    <template v-slot:search>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      <Basic-Btn text="Buscar" color="primary" icon="mdi-magnify" @click="refresh()" :loading="loading" />
    </template>
    <v-data-table :headers="headers" :items="items" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
      <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
      <template v-slot:[`item.fecha_creacion`]="{ item }">
        {{ dateFormat(item.fecha_creacion) }}
      </template>
      <template v-slot:[`item.empleado`]="{ item }">
        {{ item.empleado_solicita.detalles_usuarios.nombre + " " + item.empleado_solicita.detalles_usuarios.apellido_paterno + " " + item.empleado_solicita.detalles_usuarios.apellido_materno }}
      </template>
      <template v-slot:[`item.puesto`]="{ item }">
        {{ item.empleado_solicita.detalles_usuarios.puesto.nombre ? item.empleado_solicita.detalles_usuarios.puesto.nombre : ''}}
      </template>
      <template v-slot:[`item.departamento`]="{ item }">
        {{ item.empleado_solicita.detalles_usuarios.departamento.nombre ? item.empleado_solicita.detalles_usuarios.departamento.nombre : ''}}
      </template>
      <template v-slot:[`item.usuario_crea_solicitud`]="{ item }">
        {{ item.usuario_crea_solicitud.detalles_usuarios ? (item.usuario_crea_solicitud.detalles_usuarios.nombre ? item.usuario_crea_solicitud.detalles_usuarios.nombre : '') + ' ' + (item.usuario_crea_solicitud.detalles_usuarios.apellido_paterno ? item.usuario_crea_solicitud.detalles_usuarios.apellido_paterno : '') + ' ' + (item.usuario_crea_solicitud.detalles_usuarios.apellido_materno ? item.usuario_crea_solicitud.detalles_usuarios.apellido_materno : '') : ''}}
      </template>
      <template v-slot:[`item.siguiente_usuario_por_aprobar`]="{ item }">
        {{ item.siguiente_usuario_por_aprobar ? item.siguiente_usuario_por_aprobar.nombre_completo : ''}}
      </template>
      <template v-slot:[`item.solicitudes_prestamos`]="{ item }">
        {{ returnRequestState(item.solicitudes_prestamos) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item.idsolicitud_empleado_externo)">mdi-eye</v-icon>
          </template>
          <span>Ver detalle</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading || (item.estado !== 'Pendiente' && item.estado !== 'En proceso')" @click="remove(item.idsolicitud_empleado)">mdi-delete</v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data> No hay información disponible </template>
      <template v-slot:no-results> No se obtuvieron resultados </template>
    </v-data-table>
    <v-row class="pt-8">
      <v-spacer></v-spacer>
      <v-col cols="1">
        <v-select v-model="listPage" label="Registros" :items="[20, 50]" outlined dense></v-select>
      </v-col>
      <v-col cols="2" class="container-padding">
        <v-pagination v-model="page" :length="limitPage" :total-visible="10"></v-pagination>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la solicitud?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small :disabled="loading" color="disabled" elevation="0" style="margin: 5px" @click="cancel()">
            Cancelar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn small :loading="loading" color="error" elevation="0" style="margin: 5px" @click="remove()">
            Eliminar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";
import services from "@/utils/services";
import moment from "moment";

export default {
  name: "employee-request",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    group: null,
    search: '',
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    loading: false,
    deleted: null,
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idsolicitud_empleado" },
      { text: "Solicitante", align: "left", sortable: true, value: "empleado" },
      { text: "Puesto", align: "left", sortable: true, value: "puesto" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Coordinador", align: "left", sortable: true, value: "usuario_crea_solicitud" },
      { text: "Fecha de creación", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Estado de solicitud", align: "left", sortable: true, value: "estado" },
      { text: "Tipo de incidente", align: "left", sortable: true, value: "subtipo_solicitud.tipo_solicitud.nombre" },
      { text: "Subtipo de incidente", align: "left", sortable: true, value: "subtipo_solicitud.nombre" },
      { text: "Cantidad de días", align: "left", sortable: true, value: "dias" },
      { text: "Siguiente aprobador", align: "left", sortable: true, value: "siguiente_usuario_por_aprobar" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    page: 1,
    limitPage: 0,
    listPage: 20,
    dialogs: {
      remove: false
    }
  }),
  watch: {
    group() {
      this.drawer = false;
    },
    page() {
      this.refresh();
    },
    listPage() {
      this.refresh();
    },
  },
  computed: {
    exportItems() {
      let array = [];
      for (let data of this.items) {
        array.push({
          id: data.idsolicitud_empleado ? data.idsolicitud_empleado : "",
          empleado_solicita: data.empleado_solicita ? data.empleado_solicita.detalles_usuarios.nombre + " " + data.empleado_solicita.detalles_usuarios.apellido_paterno + " " + data.empleado_solicita.detalles_usuarios.apellido_materno : "",
          puesto: data.empleado_solicita.detalles_usuarios.puesto ? data.empleado_solicita.detalles_usuarios.puesto.nombre : "",
          departamento: data.empleado_solicita.detalles_usuarios.departamento ? data.empleado_solicita.detalles_usuarios.departamento.nombre : "",
          jefe: data.usuario_crea_solicitud.detalles_usuarios ? (data.usuario_crea_solicitud.detalles_usuarios.nombre ? data.usuario_crea_solicitud.detalles_usuarios.nombre : '') + ' ' + (data.usuario_crea_solicitud.detalles_usuarios.apellido_paterno ? data.usuario_crea_solicitud.detalles_usuarios.apellido_paterno : '') + ' ' + (data.usuario_crea_solicitud.detalles_usuarios.apellido_materno ? data.usuario_crea_solicitud.detalles_usuarios.apellido_materno : '') : '',
          fecha_creacion: data.fecha_creacion ? this.dateFormat(data.fecha_creacion) : "",
          tipo_solicitud: data.subtipo_solicitud.tipo_solicitud.nombre ? data.subtipo_solicitud.tipo_solicitud.nombre : "",
          subtipo_solicitud: data.subtipo_solicitud.nombre ? data.subtipo_solicitud.nombre : "",
          estado: data.estado ? data.estado : "",
          siguiente_usuario_por_aprobar: data.siguiente_usuario_por_aprobar ? data.siguiente_usuario_por_aprobar.nombre_completo : '',
        });
      }
      return array;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      let request = {
        params: {
          take: this.listPage,
          skip: this.page - 1,
          search: this.search,
        },
      };
      this.axios
        .get(services.routes.employeeRequest + '/list', request)
        .then((response) => {
          let data = response.data.data;
          this.items = data.data;
          this.limitPage = data.paginas;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    view(id) {
      this.$router.push({
        path: "/detalle-solicitud-empleados/" + id,
      });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    returnRequestState(info) {
      let i = info.length;
      let msg = "Sin solicitudes";
      if (i > 0) {
        msg = info[0].estatus_solicitudes_prestamos[0] ? info[0].estatus_solicitudes_prestamos[0].estatus.nombre : "No disponible";
        msg = msg[0].toUpperCase() + msg.slice(1);
      }
      return msg;
    },
    cancel() {
      if (this.dialogs.remove) {
        this.deleted = null
        this.dialogs.remove = false;
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.employeeRequest, { data: { data: { idsolicitud_empleado: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Solicitud eliminada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    }
  },
};
</script>

<style scoped>
</style>
